import fetchOld from '../js/fetchToken'

const fetch = (data, autoGetToken = false) => {
  if (!autoGetToken) {
    return fetchOld(data)
  } else {
    if (!data.data) {
      data.data = {};
    }
    if (!data.params) {
      data.params = {}
    }
    data.params.token = JSON.parse(localStorage.getItem('userInfo')).token;
    data.data.token = JSON.parse(localStorage.getItem('userInfo')).token;
    return fetchOld(data)
  }
}

// 获取邮箱验证码接口
export const sendCode = (data) => {
	return fetch({
		method: 'post',
		url: '/uc/sendCode',
		data: data
	})
}

// 邮箱验证码密码注册接口
export const login = (data) => {
	return fetch({
		method: 'post',
		url: '/uc/login',
		data: data
	})
}

// 获取邮箱验证码接口
export const sendCodeH5 = (data) => {
	return fetch({
		method: 'post',
		url: '/uc/sendCodeH5',
		data: data
	})
}

// 邮箱验证码密码注册接口
export const loginH5 = (data) => {
	return fetch({
		method: 'post',
		url: '/uc/loginH5',
		data: data
	})
}

// 获取app下载地址
export const getBehaviorParameter = (params = {}) => {
	return fetch({
		url: '/ls/flash/uc/message/getBehaviorParameter',
		params
	})
}